












































import { callsStore, teamsStore, uiStore } from '@/store'
import { debounce, throttle } from 'quasar'
import { Component, Vue, Prop } from 'vue-property-decorator'

const TEAMS_BAR_WIDTH = 64
const CHATS_BAR_MIN_WIDTH = 240

const MIN_WIDTH = TEAMS_BAR_WIDTH + CHATS_BAR_MIN_WIDTH
const MAX_WIDTH = Math.round(window.innerWidth / 3)

@Component({
  components: {
    ChatsBar: () => import('@/components/ChatsBar/index.vue'),
    TeamsBar: () => import('@/components/TeamsBar/index.vue'),
    ExternalTeamCall: () => import('@/components/ExternalTeamCall.vue'),
  },
})
export default class LeftDrawer extends Vue {
  @Prop({
    type: Boolean,
  }) isHideChats?: boolean

  private teamsBarWidth = TEAMS_BAR_WIDTH

  private get chatsBarWidth () {
    return uiStore.state.chatsBarWidth
  }

  private get drawerWidth () {
    if (this.isChatsBarHidden) return this.teamsBarWidth
    return this.teamsBarWidth + this.chatsBarWidth
  }

  private get currentTeamIsReady () {
    return !!teamsStore.getters.currentTeam
  }

  private get externalCall () {
    return callsStore.state.externalBuzz
  }

  private get isChatsBarHidden () {
    return uiStore.getters.leftColumnHidden || this.isHideChats
  }

  private get isMin () {
    return this.drawerWidth <= MIN_WIDTH
  }

  private get isMax () {
    return this.drawerWidth >= MAX_WIDTH
  }

  private handlePan ({ isFinal, position }: {
    isFinal: boolean
    position: { left: number }
  }) {
    let { left } = position

    if (left < MIN_WIDTH) {
      document.documentElement.style.cursor = 'e-resize'
      left = MIN_WIDTH
    } else if (left > MAX_WIDTH) {
      document.documentElement.style.cursor = 'w-resize'
      left = MAX_WIDTH
    } else {
      document.documentElement.style.cursor = 'ew-resize'
    }

    if (isFinal) {
      this.resizeFinalGoal(window.innerWidth, left)
      document.documentElement.style.cursor = 'auto'
      uiStore.actions.setChatsBarWidth(left - this.teamsBarWidth)
    } else {
      this.resizeGoal()
      uiStore.mutations.setChatsBarWidth(left - this.teamsBarWidth)
    }
  }

  private resizeGoal = throttle(() => {
    window.goal('leftBar', { leftBar: 'Растянуть/Сжать колонку' })
  }, 5000)

  private resizeFinalGoal = debounce(
    (windowWidth: number, leftBarWidth: number) => window.goal('leftBar', {
      leftBar: 'Размер колонки',
      px: leftBarWidth,
      percent: Math.round(leftBarWidth / windowWidth * 100),
    }),
    2000,
  )
}
